const getDefaultState = () => {
  return {
    automationStatus: {
      login: false, /* 是否登录 */
      running: false, /* 是否正在自动应答模式中 */
      working: true, /* 状态：是否工作时间 */
      url: '', /* 状态： 当前的URL */
      sendMessage: ''
    },
    automationChatList: [
      // {
      //   userName: 'Ai-Bot',
      //   message: 'Hi, how can i help you?',
      //   type: 'CustomerService',
      //   messageType: 'text',
      //   profile: ''
      // },
      // {
      //   userName: 'Jack',
      //   message: 'How do i win the jackpot?',
      //   messageState: 2,
      //   type: 'Customer',
      //   messageType: 'text',
      //   profile: ''
      // }
    ],
    automationRules: {} /* 自动应答规则数据 */
  }
}
const state = getDefaultState()
const getters = {
  automationRules: state => state.automationRules,
  automationStatus: state => state.automationStatus,
  automationChatList: state => state.automationChatList
}

const mutations = {
  SET_AUTOMATION_RULES (state, rule) {
    state.automationRules = rule
  },
  /* 设置当前运行的逻辑 */
  setRuningStep (state, stepData) {

  },
  /* 设置自动应答状态 */
  setAutomationStatus (state, status) {
    state.automationStatus = { ...state.automationStatus, ...status }
  },
  setAutomationChat (state, chat) {
    state.automationChatList.push(chat)
  },
  updateAutomationChat (state, chat) {
    state.automationChatList.forEach(item => {
      // item.
    })
  },
  /**
   * 更新发送消息内容 用于触发自动应答消息
   * @param {*} state store
   * @param {*} message 消息
   */
  updateSendMessage (state, message) {
    state.automationStatus.sendMessage = message
  }
}

const actions = {
  runSendMessageRule ({ state, commit, dispatch }, message) {
    commit('updateSendMessage', message)
    // 触发自动应答
    // console.log('automationRules', state.automationRules)
    const antomationType = state.automationRules?.links?.length ? state.automationRules?.links[0]?.type : ''
    if (antomationType === 'ChatBotIncomingMessage') {
      dispatch('setAutomationAction', state.automationRules.rule.nodeInfo.options[0].cid)
    }
  },
  runAutomationRule ({ commit, dispatch, rootState }, resultData) {
    runAutoNode(resultData.node)
    /**
     * 运行自动应答规则数据
     * @param { object } nodeInfo 节点数据
     */
    function runAutoNode (nodeInfo) {
      console.log('自动执行', nodeInfo)
      if (nodeInfo.type === 'Actions') { // 结果模块
        if (nodeInfo.dataMode === 0) { // 混合内容
          if (nodeInfo.options[0] && (nodeInfo.options[0].type === 6 || nodeInfo.options[0].type === 7 || nodeInfo.options[0].type === 'ChatBotReplyTimeout' || nodeInfo.options[0].type === "ChatBotWait")) {
            // 自动执行 - 往下一步
            const timeout = Number(nodeInfo.options[0].label || 0) // 延迟执行时间[s]
            let timer = setTimeout(() => {
              if (nodeInfo.options[0].nextRule) {
                runAutoNode(nodeInfo.options[0].nextRule.nodeInfo) // 执行下一步
                clearTimeout(timer)
                timer = null
              }
            }, timeout * 1000)
          } else {
            // 点击执行 - 显示结果
            commit('setAutomationChat', {
              userName: 'Ai-Bot',
              type: 'CustomerService',
              messageType: 'text',
              profile: rootState.botIcon,
              answer: nodeInfo.answer || [],
              options: nodeInfo.options || []
            })
            if (nodeInfo.nextRule) {
              runAutoNode(nodeInfo.nextRule.nodeInfo) // 执行下一步
            }
          }
        } else if (nodeInfo.dataMode === 1) { // POST模块
          postMessage({ type: 'automation', data: nodeInfo })
          // 1. 显示结果
          commit('setAutomationChat', {
            userName: 'Ai-Bot',
            type: 'CustomerService',
            messageType: 'text',
            profile: rootState.botIcon,
            dataMode: nodeInfo.dataMode,
            caption: nodeInfo.caption,
            description: nodeInfo.description,
            answer: nodeInfo.answer || [],
            options: nodeInfo.options || []
          })
          // 2. 检查是否有下一步
          if (nodeInfo.nextRule) {
            runAutoNode(nodeInfo.nextRule.nodeInfo) // 执行下一步
          }
        } else if (nodeInfo.dataMode === 2) { // 单节点内容
          // 1. 执行结果
          // 2. 检查是否有下一步
          if (nodeInfo.nextRule) {
            runAutoNode(nodeInfo.nextRule.nodeInfo) // 执行下一步
          }
        }
      } else if (nodeInfo.type === 'Conditions') { // 条件判断模块
        const options = nodeInfo.options || []
        const onlineStatus = state.automationStatus.online ? 'online' : 'offline' /* 是否在线 */
        const cartValue = state.automationStatus.cartValue
        const url = state.automationStatus.url
        const shopHost = state.automationStatus.shopHost
        const sendMessageContent = state.automationStatus.sendMessage

        let nextNode = null
        console.log('看下在线状态', onlineStatus);
        for (let i = 0; i < options.length; i++) {
          const option = options[i]
          if (option.condition === 0 || option.condition == 'ChatBotWorkingHours') {
            if (option.label === onlineStatus) {
              nextNode = option.nextRule
              console.log('那就走了这里啊', nextNode);
              break
            }
          } else if (option.condition === 1 || option.condition == 'ChatBotCurrentUrl') {
            if (option.logic === 0) { /* 等于 */
              if (option.label === url) {
                nextNode = option.nextRule
                break
              }
            } else if (option.logic === 1) { /* 不等于 */
              if (option.label !== url) {
                nextNode = option.nextRule
                break
              }
            } else if (option.logic === 2) { /* 包含 */
              if (url.includes(option.label)) {
                nextNode = option.nextRule
                break
              }
            } else if (option.logic === 3) { /* 不包含 */
              if (!url.includes(option.label)) {
                nextNode = option.nextRule
                break
              }
            }
          } else if (option.condition === 2 || option.condition == 'ChatBotIncomingMessageContent') { // 判断内容是否包含/不包含
            let keywords = []
            if (typeof option.label === 'number' || typeof option.label === 'string') {
              keywords = [option.lable]
              if (typeof option.label === 'string') {
                try {
                  keywords = JSON.parse(option.label)
                } catch (e) {}
              }
            } else {
              keywords = option.label
            }
            let checkReg = ''
            if (option.logic === 'matchOneOfString') {
              const escapedKeywords = keywords.map(escapeRegExp).join('|')
              checkReg = new RegExp(escapedKeywords, 'i')
            } else if (option.logic === 'matchAllInString') {
              const escapedKeywords = keywords.map(escapeRegExp).join(')(?=.*')
              checkReg = new RegExp(`^(?=.*${escapedKeywords}).*$`, 'i')
            } else if (option.logic === 'matchNotInString') {
              const escapedKeywords = keywords.map(escapeRegExp).join('|')
              checkReg = new RegExp(`^(?!.*(?:${escapedKeywords})).*$`, 'i')
            }
            // console.log('看下规则', checkReg)
            const checkResult = checkReg.test(sendMessageContent)
            // console.log('看下结果', checkResult)
            if (checkResult) {
              nextNode = option.nextRule
              break
            }
          } else if (option.condition === -1) {
            // console.log('走了ELSE', option)
            nextNode = option.nextRule
            break
          } else if (option.condition === 'ChatBotShopHost') {
            if (option.label === shopHost) {
              nextNode = option.nextRule
              break
            }
          } else if (option.condition === 'ChatBotCartAmounts') {
            if (option.logic === 'lt') {
              if (option.next && option.next.length) {
                if (option.next[0].logic === 'lt') {
                  if (Number(cartValue || 0.01) < Number(option.label) && Number(cartValue || 0.01) < Number(option.next[0].label)) {
                    nextNode = option.nextRule
                    break
                  }
                } else {
                  if (Number(cartValue || 0.01) < Number(option.label) && Number(cartValue || 0.01) > Number(option.next[0].label)) {
                    nextNode = option.nextRule
                    break
                  }
                }
              } else {
                if (Number(cartValue || 0.01) < Number(option.label)) {
                  nextNode = option.nextRule
                  break
                }
              }
            } else if (option.logic === 'gt') {
              if (option.next && option.next.length) {
                if (option.next[0].logic === 'lt') {
                  if (Number(cartValue || 0.01) > Number(option.label) && Number(cartValue || 0.01) < Number(option.next[0].label)) {
                    nextNode = option.nextRule
                    break
                  }
                } else {
                  if (Number(cartValue || 0.01) > Number(option.label) && Number(cartValue || 0.01) > Number(option.next[0].label)) {
                    nextNode = option.nextRule
                    break
                  }
                }
              } else {
                if (Number(cartValue || 0.01) > Number(option.label)) {
                  nextNode = option.nextRule
                  break
                }
              }
            }
          }
        }
        if (nextNode) {
          runAutoNode(nextNode.nodeInfo)
        }
      }
    }
    /**
     * 考虑一些旧浏览器不支持ES8的RegExp.escape解析，这里手动撸一个解析特殊字符算法，解析有问题的话改这里
     * @param { String } string 解析字符/文本
     * @returns { String } 转义后内容
     */
    function escapeRegExp (string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    }
  },
  /* 设置自动应答的相关规则 */
  setAutomationAction ({ state, commit, dispatch, rootState }, cid) {
    /* 1. 拿到对应的规则相关内容 */
    return new Promise((resolve, reject) => {
      /* 运行下一次的结果 */
      var resultData = {}
      // console.log('初始的数据', resultData);
      runNextResult(state.automationRules.links, cid, state, resultData)
      console.log('结果内容', resultData)
      dispatch('runAutomationRule', resultData)
    })
  },
  /* 设置自动应答的相关规则演示 */
  setAutomationActionDemo ({ commit, dispatch, rootState }, data) {
    const { rule, setting } = data
    console.log('获取到的演示数据', rule, data)
    commit('SET_AUTOMATION_RULES', rule)
    commit('setAutomationStatus', setting)
    /* 开始预览 这里要结合预览的数据，如果预览数据中的起点是[打开新会话]事件，那么就直接运行下一步 */
    const triggerList = ['ChatBotCustomerVisitsPage', 'ChatBotCustomerOpenNewConversation', 'ChatBotIncomingMessage', 'ChatBotCartBaseAbondoned', 'ChatBotCartBrowseAbandoned', 'ChatBotCartRemoveAbandoned', 'ChatBotCartCheckoutAbandoned']
    if (rule.rule.nodeInfo.options[0].type === 0 || triggerList.includes(rule.rule.nodeInfo.options[0].type)) {
      dispatch('setAutomationAction', rule.rule.nodeInfo.options[0].cid)
    } else {
      console.log('其他类型的数据', rule.rule.nodeInfo)
    }
  }
}
// 获取下一步规则结果内容【这里要注意判断的条件】
function runNextResult (ruleLinks, cid, state, resultData = {}) {
  /* 如果当前的触发是条件，那么就往下一级找，看能不能找到结果 */
  let nextNodeInfo = {}
  if (cid) {
    const findData = ruleLinks.filter(rule => rule.cid === cid)
    let rule = null
    rule = findData[0]
    nextNodeInfo = rule.nextRule.nodeInfo
  } else {
    nextNodeInfo = ruleLinks
  }
  console.log('下一级信息', nextNodeInfo)
  resultData.node = nextNodeInfo
}

function postMessage (data) {
  parent.postMessage(data, '*')
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
